import { render, staticRenderFns } from "./StripeDemoForm.vue?vue&type=template&id=23971e55&scoped=true"
import script from "./StripeDemoForm.vue?vue&type=script&lang=js"
export * from "./StripeDemoForm.vue?vue&type=script&lang=js"
import style0 from "./StripeDemoForm.vue?vue&type=style&index=0&id=23971e55&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23971e55",
  null
  
)

export default component.exports